

































import ClientSelectInput from '@/components/inputs/ClientSelectInput.vue';
import Component from 'vue-class-component';
import DepartmentSelectInput from '@/components/inputs/DepartmentSelectInput.vue';
import PositionSelectInput from '@/components/inputs/PositionSelectInput.vue';
import ShiftSelectInput from '@/components/inputs/ShiftSelectInput.vue';
import SiteSelectInput from '@/components/inputs/SiteSelectInput.vue';
import Vue from 'vue';
import { Client, Department, Position, Shift, Site } from '@/models';
import { Prop, PropSync } from 'vue-property-decorator';

@Component({
  components: {
    ClientSelectInput,
    SiteSelectInput,
    DepartmentSelectInput,
    PositionSelectInput,
    ShiftSelectInput,
  },
  name: 'AddClientLocation',
})
export default class extends Vue {
  valid = false;

  @PropSync('client', { type: [String, Object] })
  syncedClient!: string | Client | null;

  @PropSync('site', { type: [String, Object] })
  syncedSite!: string | Site | null;

  @PropSync('department', { type: [String, Object] })
  syncedDepartment!: string | Department | null;

  @PropSync('shift', { type: [String, Object] })
  syncedShift!: string | Shift | null;

  @PropSync('position', { type: [String, Object] })
  syncedPosition!: string | Position | null;

  @Prop({ type: [String, Object], default: null, required: true })
  readonly target!: string | null;
}
