


























import Component from 'vue-class-component';
import Vue from 'vue';
import { Client, Department, Site } from '@/models';
import { Prop, VModel } from 'vue-property-decorator';

@Component({
  name: 'ClientSiteDepartmentsList',
})
export default class extends Vue {
  @Prop({ type: String }) readonly sectionLabel!: string;
  @Prop({ type: Array }) readonly items!: Client[] | Site[] | Department[];
  @VModel({ type: Object }) readonly item!: Client | Site | Department;
  // @Prop({type: Boolean}) readonly active!: boolean;
}
