







































































import AddClientLocation from './forms/AddClientLocation.vue';
import ClientSiteDepartmentsList from './ClientSiteDepartmentsList.vue';
import Component from 'vue-class-component';
import Vue from 'vue';
import { Client, Department, Site } from '@/models';
import { Position } from '@/models/Position';
import { Shift } from '@/models/Shift';
import { Watch } from 'vue-property-decorator';

@Component({
  components: { ClientSiteDepartmentsList, AddClientLocation },
  name: 'ClientSiteDepartments',
})
export default class extends Vue {
  client: Client | null = null;
  site: Site | null = null;
  department: Department | null = null;
  shift: Shift | null = null;
  position: Position | null = null;

  sites: Site[] = [];
  departments: Department[] = [];
  shifts: Shift[] = [];
  positions: Position[] = [];

  adding = false;

  target: string | null = null;

  addItem(item: string): void {
    this.target = item;
    this.adding = true;
  }

  async mounted(): Promise<void> {
    await this.fetchClient();
    await this.fetchSites();
  }

  async fetchClient(): Promise<void> {
    this.client = await Client.find(this.$route.params.uuid);
  }

  async fetchSites(): Promise<void> {
    if (this.client) {
      this.sites = await this.client.sites().get();
    }
  }

  async fetchDepartments(): Promise<void> {
    if (this.site) {
      this.departments = await this.site.departments().get();
    }
  }

  async fetchPositions(): Promise<void> {
    if (this.department) {
      this.positions = await this.department.positions().get();
    }
  }

  async fetchShifts(): Promise<void> {
    if (this.department) {
      this.shifts = await this.department.shifts().get();
    }
  }

  @Watch('site')
  onSiteChanged(): void {
    this.department = null;
    this.fetchSites();
    this.fetchDepartments();
  }

  @Watch('department')
  onDepartmentChanged(): void {
    this.fetchDepartments();
    this.fetchPositions();
    this.fetchShifts();
  }

  @Watch('position')
  onPositionChanged(): void {
    this.fetchPositions();
  }

  @Watch('shift')
  onShiftChanged(): void {
    this.fetchShifts();
  }
}
